.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.service {
  background: var(--bg-color-variant);
  border-radius: 0 0 2rem 2rem;
  border: 1px solid var(--primary-color);
  height: fit-content;
  transition: var(--transition);
}

.service:hover {
  background: transparent;
  border-color: var(--primary-color-variant);
  cursor: default;
}

.service__head {
  background: var(--primary-color);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
  color: var(--bg-color);
  font-size: 1rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service__list_icon {
  color: var(--primary-color);
  margin-top: 2px;
}

.service__list p {
  font-size: 0.9rem;
}

.service__list a {
  color: black;
}

.service__list a:hover {
  color: black;
}

.link-secweb {
  color: var(--primary-color-variant);
}

/* ================== Media (Medium) ================== */
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .service {
    height: auto;
  }
}

/* ================== Media (Small) ================== */
@media screen and (max-width: 600px) {
  .services__container {
    grid: 1fr;
    gap: 1.5rem;
  }
}
